import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Card, CardHeader, CardBody, Row, Col, Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ApiService } from 'services'
import Benefit from './Benefit'
import Search from './Search'
import config from 'config'
import moment from 'moment'

import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

function Show () {
  const { id } = useParams()

  const [ formData, setFormData ] = useState({})
  const [ activeBenefit, setActiveBenefit ] = useState(-1)

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState({
    search: ''
  })

  // Carga inicial de datos
  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchData = async (newOptions) => {
    try {
      // Merge de las nuevas opciones con las viejas
      const options = Object.assign({}, currentOptions, newOptions)

      // Hacemos la petición
      const res = await ApiService.getOne('presentations', id, options)

      // Guardamos las opciones
      setCurrentOptions(options)

      const data = {
        ...res.payload,
        summary_file: res.payload.files.find(f => f.type === 'summary_file') || '',
        detail_file: res.payload.files.find(f => f.type === 'detail_file') || '',
        extra_file: res.payload.files.find(f => f.type === 'extra_file') || ''
      }

      delete data.files

      setFormData(data)
    } catch (err) {
      console.error(err)
    }
  }

  const search = (query) => {
    fetchData({
      search: query
    })
  }

  const downloadFile = (url) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = file;
        link.download = `remito_${ formData.submission_id }.txt`
        link.click();
      });
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Search onSearch={ search } currentQuery={ currentOptions.search } />

        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Presentación #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/presentations'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <Row className="form-group mb-4">
            <Col md="6">
              <label className="form-control-label">N° de Remito</label>
              <Input
                type="number"
                defaultValue={ formData.submission_id }
                name="submission_id"
                disabled={ true }
              />
            </Col>
          </Row>
          {
            formData.remito
            ? <>
                <Row className="form-group mb-4">
                  <Col md="6">
                    <label className="form-control-label">Obra Social</label>
                    <Input
                      type="text"
                      defaultValue={ formData.remito.obraSocial }
                      name="obraSocial"
                      disabled={ true }
                    />
                  </Col>
                  <Col md="6">
                    <label className="form-control-label">Cantidad de Prestaciones</label>
                    <Input
                      type="number"
                      defaultValue={ formData.remito.cantidadPrestaciones }
                      name="cantidadPrestaciones"
                      disabled={ true }
                    />
                  </Col>
                </Row>
                <Row className="form-group mb-4">
                  {
                    formData.remito?.zip
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Documentación ZIP</label><br />
                        <a className="btn btn-primary" href={ `https://ticket.test.cokiba.org.ar/compartida/remito_${ formData.submission_id.toString().padStart(10, '0') }.zip` } target="_blank" rel="noopener noreferrer">Descargar</a>
                      </Col>
                    : null
                  }
                  {
                    formData.remito?.pdf && formData.remito?.pdf.chunks
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Documentación PDF</label><br />
                        <UncontrolledDropdown>
                          <DropdownToggle color="primary" caret>Descargar PDF</DropdownToggle>
                          <DropdownMenu>
                            {
                              [...Array(formData.remito.pdf.chunks)].map((e, i) => (
                                  <DropdownItem key={ `link_${ i }` }>
                                    <a href={ `https://ticket.test.cokiba.org.ar/compartida/remito_${ formData.submission_id.toString().padStart(10, '0') }_${ (i + 1).toString().padStart(3, '0') }.pdf` } target="_blank" rel="noopener noreferrer">
                                      Parte #{ i + 1 }
                                    </a>
                                  </DropdownItem>
                              ))
                            }
                            
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    : null
                  }
                  {
                    formData.remito?.billingFile
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Archivo Sincronización</label><br />
                        <a className="btn btn-primary" href={ config.baseUrl + '/' + formData.remito.billingFile } target="_blank" rel="noopener noreferrer">Ver Archivo</a>
                        <Button color="primary" onClick={ () => downloadFile(config.baseUrl + '/' + formData.remito.billingFile) }>Descargar</Button>
                      </Col>
                    : null
                  }
                  {
                    formData.summary_file
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Resumen</label><br />
                        <a className="btn btn-primary" href={ config.baseUrl + '/' + formData.summary_file.path } target="_blank" rel="noopener noreferrer">Ver Archivo</a>
                      </Col>
                    : null
                  }
                  {
                    formData.detail_file
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Detalle</label><br />
                        <a className="btn btn-primary" href={ config.baseUrl + '/' + formData.detail_file.path } target="_blank" rel="noopener noreferrer">Ver Archivo</a>
                      </Col>
                    : null
                  }
                  {
                    formData.extra_file
                    ? <Col sm="4" className="text-center">
                        <label className="form-control-label">Adjunto</label><br />
                        <a className="btn btn-primary" href={ config.baseUrl + '/' + formData.extra_file.path } target="_blank" rel="noopener noreferrer">Ver Archivo</a>
                      </Col>
                    : null
                  }
                </Row>
                <Row className="form-group mb-4">
                  <Col>
                    <label className="form-control-label">Prestaciones</label>
                    <table className="align-items-center table-flush table">
                      <thead>
                        <tr>
                          <th>Nombre Matriculado</th>
                          <th>Afiliado</th>
                          <th>Práctica</th>
                          <th>N° Autorización</th>
                          <th>Fecha Prescripción</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          formData.benefits && Array.isArray(formData.benefits)
                          ? formData.benefits.map(benefit => (
                              <React.Fragment key={`benefit_${ benefit.id }`}>
                                <tr id={`benefit_${ benefit.id }`}>
                                  <td>{ benefit.matriculate?.apellido } { benefit.matriculate?.nombre }</td>
                                  <td>{ benefit.numero_afiliado }</td>
                                  <td>{ benefit.tipo_practica }</td>
                                  <td>{ benefit.numero_aprobacion }</td>
                                  <td>{ benefit.fecha_prescripcion_medica ? moment(benefit.fecha_prescripcion_medica, 'YYYY-MM-DD').format('DD/MM/YYYY') : ' - ' }</td>
                                  <td>
                                    {
                                      activeBenefit === benefit.id
                                      ? <Button color="primary" size="sm" onClick={ () => setActiveBenefit(-1) }>
                                          <i className="fa fa-eye-slash"></i>
                                        </Button>
                                      : <Button color="primary" size="sm" onClick={ () => setActiveBenefit(benefit.id) }>
                                          <i className="fa fa-eye"></i>
                                        </Button>
                                    }
                                  </td>
                                </tr>
                                {
                                  activeBenefit === benefit.id
                                  ? <tr id={`benefit_${ benefit.id }_details`}>
                                      <td colSpan={ 5 }>
                                        <Benefit id={ benefit.id } />
                                      </td>
                                    </tr>
                                  : null
                                }
                              </React.Fragment>
                            ))
                          : <tr>
                              <td colSpan={5} className="text-center">No hay datos para mostrar...</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </>
            : null
          }
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Show
